import Vue from 'vue'
import scs from 'shared/scs'
import * as common from 'shared/store/common.js'

// initial state
const commissionsState = {
  loading: false,
  commissions: {},
  commissionsSummary: {}
}

// getters
const getters = {
  all(state) {
    return Object.keys(state.commissions).map((k) => state.commissions[k])
           .sort()
           .reverse()
  },
  summary: (state) => state.commissionsSummary,
}

// actions
const actions = {
  async fetch({ commit }, data = {}) {
    commit('setLoading', true)
    const response = await scs.getProductCommissions(data)
    const commissions = await response.json()
    commit('setPagination', response.headers)
    commit('appendProductCommissions', commissions.data)
    commit('setCommissionsSummary', commissions.stats)
    commit('setLoading', false)
    return commissions.data
  },
  reset({ commit }) {
    commit('clearCommissions')
  },
}

// mutations
const mutations = {
  appendProductCommissions(state, commissions) {
    commissions.forEach((c) => {
      Vue.set(state.commissions, c.id, c)
    })
  },

  clearCommissions(state) {
    Vue.set(state, 'commissions', {})
  },

  setLoading(state, on) {
    state.loading = on
  },

  setCommissions(state, commissions) {
    Vue.set(state, 'commissions', commissions)
  },

  setCommissionsSummary(state, stats) {
    state.commissionsSummary = stats
  },
}

export default {
  namespaced: true,
  state: { ...common.state, ...commissionsState },
  mutations: { ...common.mutations, ...mutations },
  actions: { ...common.actions, ...actions },
  getters: { ...common.getters, ...getters }
}
