import designServices from 'shared/mixins/design_services'

export default {
  mixins: [designServices],
  methods: {
    // Initiates the generation of the PDF invoice.
    downloadInvoice(publicStore = false) {
      if (!this.creatingPdf) {
        const store = publicStore ? 'order' : 'orders'
        const id = publicStore ? this.order.token : this.order.id
        this.$store.dispatch(`${store}/invoice`, { id, client_id: this.$store.getters['app/clientGuid'] })
          .then(() => {
            this.creatingPdf = true
          })
          .catch(() => {
            this.$buefy.toast.open({
              message: 'Oops! Something went wrong, and we were unable to create your invoice. Please try again shortly.',
              type: 'is-danger',
              duration: 4000
            })
          })
      }
    },

    downloadPackingSlip(publicStore = false) {
      if (!this.creatingPdf) {
        const store = publicStore ? 'shippedPackage' : 'shippedPackages'
        const shippedPackage = { ...this.shippedPackage }
        if (publicStore) {
          shippedPackage.order_id = this.order.token
        }
        this.$store.dispatch(`${store}/packingSlip`, { shippedPackage, client_id: this.$store.getters['app/clientGuid'] })
          .then(() => {
            this.creatingPdf = true
          })
          .catch(() => {
            this.$buefy.toast.open({
              message: 'Oops! Something went wrong, and we were unable to create your packing slip. Please try again shortly.',
              type: 'is-danger',
              duration: 4000
            })
          })
      }
    },

    // Creates a local URL for the invoice and downloads it.
    exportInvoice(data) {
      fetch(data.document_url)
        .then(response => response.blob())
        .then(blob => {
          const localUrl = window.URL.createObjectURL(blob)

          this.forceDownload(localUrl, `${this.order.reference_number} invoice.pdf`)
          window.URL.revokeObjectURL(localUrl)
        })
    },

    forceDownload(blob, filename = '') {
      const a = document.createElement('a')
      a.download = filename
      a.href = blob
      // For Firefox https://stackoverflow.com/a/32226068
      document.body.appendChild(a)
      a.click()
      a.remove()
    },

    invoiceUrl(print) {
      let url = this.order.invoice_path
      if (this.isLinkedDesignServiceOrder(this.order)) {
        url = this.linkedDesignServiceOrderInvoicePath(this.order)
      }

      if (print) {
        url += "&print=1"
      }

      return url
    },

    invoiceShareUrl(print = false) {
      const appHost = this.$store.getters['app/appHost']
      return `${appHost}${this.invoiceUrl(print)}`
    },

    printInvoice(event) {
      if (event && event.shiftKey) {
        window.open(this.invoiceShareUrl(), '', 'left=0,top=0,width=500,height=400,toolbar=0,scrollbars=0,status =0')
      } else {
        this.$store.dispatch('print/print', [this.invoiceUrl()])
      }
    },
  }
}
