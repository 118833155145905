import Vue from 'vue'
import App from 'customer/components/app.vue'
import router from 'customer/router'
import store from 'customer/store'
import initCore from 'customer/components/core'
import VueAbly from "shared/ably"
import VueMq from 'vue-mq'
import * as SCS from 'shared/util/scs'
import { applyVueRouterErrorHandler } from 'shared/extensions/vue_router_error_handler'

// Apply the error handler before initializing Vue and Vue Router
applyVueRouterErrorHandler()

window.SCS = SCS

// MatchMediaAPI Polyfill - Ensures the vue-mq library can be used on older browsers
require('matchmedia-polyfill')
require('matchmedia-polyfill/matchMedia.addListener')

document.addEventListener('DOMContentLoaded', () => {
  document.body.appendChild(document.createElement('app'))

  initCore()

  Vue.use(VueMq, {
    breakpoints: {
      mobile: 768,
      tablet: 1024,
      desktop: Infinity,
    }
  })

  Vue.use(new VueAbly({
    debug: window.ablyDebug,
    connection: {
      authUrl: '/auth_socket',
      authMethod: 'GET'
    },
    vuex: {
      store,
      actionPrefix: "SOCKET_",
      mutationPrefix: "SOCKET_"
    }
  }))

  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('app')
})
