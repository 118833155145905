/*
 * Vue Router Error Handler
 *
 * Silently handles NavigationDuplicated errors while preserving other error logging.
 * Wraps router.push and router.replace to catch navigation errors.
 *
 * Context: https://github.com/vuejs/vue-router/issues/2963#issuecomment-541066206
 *
 * Usage: Import and call applyVueRouterErrorHandler() in your router setup.
 *
 * Note: Applied globally. For specific handling, use local try/catch.
 */

import Router, { NavigationFailureType } from 'vue-router'

export function applyVueRouterErrorHandler() {
  ['push', 'replace'].forEach(method => {
    const original = Router.prototype[method]
    Router.prototype[method] = function routerMethod(location, onResolve, onReject) {
      if (onResolve || onReject) {
        return original.call(this, location, onResolve, onReject)
      }
      return original.call(this, location).catch(err => (
        Router.isNavigationFailure(err, NavigationFailureType.duplicated) ? err : Promise.reject(err)
      ))
    }
  })
}
