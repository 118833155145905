import Vue from 'vue'
import Vuex from 'vuex'

import {
  app,
  attachments,
  cart,
  customer,
  designRequests,
  drawings,
  folders,
  intercom,
  materials,
  orders,
  organization,
  parts,
  pendingNotifications,
  commissions,
  customerProducts,
  quotes,
  savedCarts,
  tickets
} from './modules'

import {
  quoteDialog,
  quoteUi,
  bending,
  customerProductUi,
} from './modules/ui'

import {
  addresses,
  drawing_models as drawingModels,
  finish_operations as finishOperations,
  finish_options as finishOptions,
  notes,
  notifications,
  hardware,
  hole_operations as holeOperations,
  part_builder_dialog as partBuilderDialog,
  print,
  tracking,
} from 'shared/store'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
  },
  modules: {
    addresses,
    app,
    attachments,
    cart,
    customer,
    design_requests: designRequests,
    drawing_models: drawingModels,
    drawings,
    folders,
    intercom,
    finish_options: finishOptions,
    hardware,
    materials,
    notes,
    notifications,
    orders,
    organization,
    parts,
    pending_notifications: pendingNotifications,
    print,
    commissions,
    customer_products: customerProducts,
    quotes,
    saved_carts: savedCarts,
    tickets,
    tracking,
    // UI state modules
    finish_operations: finishOperations,
    hole_operations: holeOperations,
    part_builder_dialog: partBuilderDialog,
    quote_dialog: quoteDialog,
    quote_ui: quoteUi,
    bending,
    customer_product_ui: customerProductUi,
  }
})

export default store
