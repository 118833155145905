export const isMarketplaceProductItem = (item) => item?.item_type === 'marketplace_product'

export const isProductionItem = (item) => item?.item_type === 'production'

export const isNonProductionItem = (item) => item?.item_type === 'non_production'

export const isDesignServicesDrawingItem = (item) => item?.item_type === 'design_services_drawing'

export const isDesignServicesTemplateItem = (item) => item?.item_type === 'design_services_template'

export const isDesignServiceItem = (item) => isDesignServicesDrawingItem(item) || isDesignServicesTemplateItem(item)

export const isDesignServicesOrder = (order) => order?.reference_number[0] === 'D'

  // Returns true if this order is not the purchased order, but rather is generated from the original.
export const isLinkedDesignServiceOrder = (order) => isDesignServicesOrder(order) && Number(order.total_subtotal) === 0

export const isDesignServicesDrawingOrder = (order) => {
  const dsLineItems = order?.line_items.filter(item => isDesignServiceItem(item))
  return isDesignServicesOrder(order) && isDesignServicesDrawingItem(dsLineItems[0])
}

export const isDesignServicesTemplateOrder = (order) => {
  const dsLineItems = order?.line_items.filter(item => isDesignServiceItem(item))
  return isDesignServicesOrder(order) && isDesignServicesTemplateItem(dsLineItems[0])
}
